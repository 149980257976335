$blue: #1a73e8;

$light-blue: #53A9F8;

$red: #c7041b;

$white: #fff;

$other-white: #f5f5f5;

$black: #000;

$light-black: #293347;

$border-color: #E6ECF8;

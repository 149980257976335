@import "../../veriables.scss";

.button {
  font-weight: 700;
  font-size: 1rem;
  background-color: transparent;
  border: 2px solid $blue;
  border-radius: 30px;
  transition: 0.2s ease;
  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
    background-color: $blue;
  }
}

.white {
  padding: 10px 20px;
  color: $blue;
  &:hover {
    color: $white;
  }
}

.red {
  padding: 10px 20px;
  border: 2px solid $red;
  color: $red;
  &:hover {
    color: $white;
    background-color: $red;
  }
}


.black {
  font-size: 1.2rem;
  padding: 12px 30px;
  color: $white;
}

.work {
  background-color: $other-white;
  border: 2px solid $light-black;
  padding: 15px 30px;
  color: $black;
  &:hover {
    background-color: $white;
    border: 2px solid $light-black;
  }
}

@import '../../veriables.scss';

.form {
  margin: auto;
  margin-top: 40px;
  box-sizing: border-box;
  text-align: left;
  max-width: 700px;
  width: auto;

  label {

    font-size: 1.1rem;
    display: block;
    margin-bottom: 10px;
  }

  input, textarea {
    width: 100%;

    border-radius: 4px;
    font-weight: 400;
    box-sizing: border-box;
    border: 1px solid $border-color;
    padding: 10px;
    &:focus {
      outline: none;
    }
  }

  input {
    font-size: 1.4rem;
  }

  textarea {
    height: 200px;
    resize: vertical;
    font-size: 1.8rem;
  }

  button {
    margin-top: 50px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 25px;
    border: none;
    color: $blue;
    background-color: inherit;
    border: 2px solid $blue;
    transition: all .2s ease;
    font-size: 1.2rem;
    height: 45px;
    width: 200px;

    &:hover {
      cursor: pointer;
      color: $white;
      background-color: $blue;
    }
  }
}

.name, .email {
  display: inline-block;
  margin-bottom: 30px;
}

.name {
  width: 47%;
  margin-right: 3%;
}

.email {
  width: 50%;
}


@media screen and (max-width: 769px) {
  button {
    width: 100% !important;
  }
}
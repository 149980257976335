@import "./veriables.scss";

body {
  font-family: "Poppins", sans-serif;
  background-color: $white;
}

.App {
  position: relative;
  background-color: #f5f5f5;
  float: left;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

#style-1::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
#style-1::-webkit-scrollbar-thumb {
  background-color: #000000;
}
#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

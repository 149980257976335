@import '../../veriables.scss';

.paragraph {
  color: inherit;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2rem;
  justify-content: center;

  a {
    color: $blue;
    text-decoration: none;
  }
}

.mar {
  margin-bottom: 50px;
}

.colored {
  color: $blue;
}

.lg {
  font-size: 1.2rem;
}
.md {
  font-size: 1.0rem;
}
.sm {
  font-size: .8rem;
}

